.footer{

    [class*="container-"] {
      padding: 0;
      margin-bottom: -35px;
      color: white;
     
    }



    .nav-link {
      color: $white;
     margin-bottom: -60px;
     line-height: .5;
      font-size: 12px;
      text-decoration: none;

      &:hover{
          text-decoration: none;
      }
    }

  
    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}


@media screen and (min-width: 992px){
  .footer {
    .copyright {
      float: right;
      margin-bottom: 0rem;
      margin-right: 1020px;
      
    }
  }
}

@media screen and (max-width: 768px){
  .footer {
    nav {
      display: block;
      margin-bottom: 50px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px){
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav{
      float: none;
      padding-left: 0;
    }
  }
}
